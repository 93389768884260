export const SET_PROFILE = "SET_PROFILE";
export const SET_USERDETAIL = "SET_USERDETAIL";
export const SET_WHITELABELING = "SET_WHITELABELING";
export const SET_ROLES = "SET_ROLES";
export const SET_BRAND_COLOR = "SET_BRAND_COLOR";
export const SET_BRAND_DARK_COLOR = "SET_BRAND_DARK_COLOR";

export const setRoles = roles => ({
  type: SET_ROLES,
  payload: {roles}
});

export const setWhiteLabeling = response => ({
  type: SET_WHITELABELING,
  payload: {response}
});

export const setUserResponse = object => ({
    type: SET_USERDETAIL,
    payload: {object}
});

export const setProfileData = profile => ({
    type: SET_PROFILE,
    payload: { profile }
});

export const  setBrandColor = brandColor => ({
    type: SET_BRAND_COLOR,
    payload: brandColor
});

export const  setBrandDarkColor = brandDarkColor => ({
    type: SET_BRAND_DARK_COLOR,
    payload: brandDarkColor
});
