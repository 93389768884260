const SERVER_ENV = window && window.location && window.location.hostname;

let ENV_APP_BASE;
let ENV_API_BASE;
let ENV_API_BASE_URL;
let ENV_USER_BASE;
let ENV_USER_BASE_URL;
let ENV_ADMIN_BASE;
let ENV_BASE_COOKIE;
let ENV_ROLLBAR_ACCESS_TOKEN;
// Replace IF conditions as per the base URL

let ENV_EUREKA_HOST_NAME;
let ENV_EUREKA_IP_ADDRESS;
let ENV_EUREKA_STATUS_PAGE_URL;
let ENV_EUREKA_CLIENT_PORT;
let ENV_EUREKA_HOST;
let ENV_EUREKA_SERVER_PORT;
let ENV_EUREKA_SERVICE_PATH;
let ENV_REPORT_BASE;
let ENV_REPORT_UI;
let ENV_BI_UI;
let ENV_STRAGILE_BASE;
export const APP_BASE = ENV_APP_BASE;

if (SERVER_ENV.includes('localhost')) {

    ENV_APP_BASE = 'http://localhost:3000';

    ENV_API_BASE = 'http://localhost:8076/api/authmodule';
    ENV_API_BASE_URL = ENV_API_BASE + '';
    ENV_USER_BASE = 'http://localhost:8076/api/usermodule';
    ENV_USER_BASE_URL = 'http://localhost:3001';
    ENV_ADMIN_BASE = 'http://localhost:8076/api/adminmodule';
    ENV_REPORT_BASE = 'http://localhost:8076/api/reportmodule';
    ENV_STRAGILE_BASE = 'http://localhost:8076/api/stragilemodule';
    ENV_REPORT_UI = 'http://localhost:3012';
    ENV_BI_UI = 'http://localhost:3012';

    ENV_API_BASE = 'https://universegateway.api.testing.kpininja.com/api/authmodule';
    ENV_API_BASE_URL = ENV_API_BASE + '';
    ENV_USER_BASE = 'https://universegateway.api.testing.kpininja.com/api/usermodule';
    ENV_USER_BASE_URL = 'https://user.testing.kpininja.com';
    ENV_ADMIN_BASE = 'https://universegateway.api.testing.kpininja.com/api/adminmodule';
    ENV_REPORT_BASE = 'https://universegateway.api.testing.kpininja.com/api/reportmodule'
    ENV_STRAGILE_BASE = 'https://universegateway.api.testing.kpininja.com/api/stragilemodule';
    ENV_REPORT_UI = 'https://report.testing.kpininja.com';
    ENV_BI_UI = 'https://bi.testing.kpininja.com';

    // ENV_API_BASE = 'https://universegateway.api.preproduction.kpininja.com/api/authmodule';
    // ENV_API_BASE_URL = ENV_API_BASE + '';
    // ENV_USER_BASE = 'https://universegateway.api.preproduction.kpininja.com/api/usermodule';
    // ENV_USER_BASE_URL = 'https://user.preproduction.kpininja.com';
    // ENV_ADMIN_BASE = 'https://universegateway.api.preproduction.kpininja.com/api/adminmodule';
    // ENV_REPORT_BASE = 'https://universegateway.api.preproduction.kpininja.com/api/reportmodule';
    // ENV_STRAGILE_BASE = 'https://universegateway.api.preproduction.kpininja.com/api/stragilemodule';
    // ENV_REPORT_UI = 'https://report.preproduction.kpininja.com';
    // ENV_BI_UI = 'https://bi.preproduction.kpininja.com';

    ENV_BASE_COOKIE = 'localhost';
    ENV_ROLLBAR_ACCESS_TOKEN = "";

    // ENV_EUREKA_HOST_NAME = 'localhost';
    // ENV_EUREKA_IP_ADDRESS = 'localhost';
    // ENV_EUREKA_STATUS_PAGE_URL = 'http://localhost:3001';
    // ENV_EUREKA_CLIENT_PORT = 3001;
    // ENV_EUREKA_HOST = 'localhost';
    // ENV_EUREKA_SERVER_PORT = 8081;
    // ENV_EUREKA_SERVICE_PATH = '/oauth-server/eureka/apps/';

} else if (SERVER_ENV.includes('-v4.testing.kpininja.com')) {
    ENV_APP_BASE = 'https://universe-auth-v4.testing.kpininja.com';
    ENV_API_BASE = 'https://universe-proxy-v4.testing.kpininja.com/api/authmodule';
    ENV_API_BASE_URL = ENV_API_BASE + '';
    ENV_USER_BASE = 'https://universe-proxy-v4.testing.kpininja.com/api/usermodule';
    ENV_USER_BASE_URL = 'https://universe-user-v4.testing.kpininja.com';
    ENV_ADMIN_BASE = 'https://universe-proxy-v4.testing.kpininja.com/api/adminmodule';
    ENV_REPORT_BASE = 'https://universegateway.api.testing.kpininja.com/api/reportmodule'
    ENV_STRAGILE_BASE = 'https://universegateway.api.testing.kpininja.com/api/stragilemodule';
    ENV_REPORT_UI = 'https://report.testing.kpininja.com';
    ENV_BI_UI = 'https://bi.testing.kpininja.com';
    ENV_BASE_COOKIE = '.testing.kpininja.com';
    ENV_ROLLBAR_ACCESS_TOKEN = "b88107f336944c1687be4dd5560b1578";

    ENV_EUREKA_HOST_NAME = 'admin.testing.kpininja.com';
    ENV_EUREKA_IP_ADDRESS = 'admin.testing.kpininja.com';
    ENV_EUREKA_STATUS_PAGE_URL = 'http://admin.testing.kpininja.com';
    ENV_EUREKA_CLIENT_PORT = 80;
    ENV_EUREKA_HOST = 'universe.api.testing.kpininja.com';
    ENV_EUREKA_SERVER_PORT = 443;
    ENV_EUREKA_SERVICE_PATH = '/eureka/apps/';
} else if (SERVER_ENV.includes('testing.kpininja.com')) {
    ENV_APP_BASE = 'https://universe.testing.kpininja.com';
    ENV_API_BASE = 'https://universegateway.api.testing.kpininja.com/api/authmodule';
    ENV_API_BASE_URL = ENV_API_BASE + '';
    ENV_USER_BASE = 'https://universegateway.api.testing.kpininja.com/api/usermodule';
    ENV_USER_BASE_URL = 'https://user.testing.kpininja.com';
    ENV_ADMIN_BASE = 'https://universegateway.api.testing.kpininja.com/api/adminmodule';
    ENV_REPORT_BASE = 'https://universegateway.api.testing.kpininja.com/api/reportmodule'
    ENV_STRAGILE_BASE = 'https://universegateway.api.testing.kpininja.com/api/stragilemodule';
    ENV_REPORT_UI = 'https://report.testing.kpininja.com';
    ENV_BI_UI = 'https://bi.testing.kpininja.com';
    ENV_BASE_COOKIE = '.testing.kpininja.com';
    ENV_ROLLBAR_ACCESS_TOKEN = "b88107f336944c1687be4dd5560b1578";

    ENV_EUREKA_HOST_NAME = 'admin.testing.kpininja.com';
    ENV_EUREKA_IP_ADDRESS = 'admin.testing.kpininja.com';
    ENV_EUREKA_STATUS_PAGE_URL = 'http://admin.testing.kpininja.com';
    ENV_EUREKA_CLIENT_PORT = 80;
    ENV_EUREKA_HOST = 'universe.api.testing.kpininja.com';
    ENV_EUREKA_SERVER_PORT = 443;
    ENV_EUREKA_SERVICE_PATH = '/eureka/apps/';
} else if (SERVER_ENV.includes('staging.kpininja.com')) {
    ENV_APP_BASE = 'https://universe.staging.kpininja.com';
    ENV_API_BASE = 'https://universegateway.api.staging.kpininja.com/api/authmodule';
    ENV_API_BASE_URL = ENV_API_BASE + '';
    ENV_USER_BASE = 'https://universegateway.api.staging.kpininja.com/api/usermodule';
    ENV_USER_BASE_URL = 'https://user.staging.kpininja.com';
    ENV_ADMIN_BASE = 'https://universegateway.api.staging.kpininja.com/api/adminmodule';
    ENV_REPORT_BASE = 'https://universe.api.staging.kpininja.com/api/reportmodule';
    ENV_STRAGILE_BASE = 'https://universe.api.staging.kpininja.com/api/stragilemodule';
    ENV_REPORT_UI = 'https://report.staging.kpininja.com';
    ENV_BI_UI = 'https://bi.staging.kpininja.com';
    ENV_BASE_COOKIE = '.staging.kpininja.com';
    ENV_ROLLBAR_ACCESS_TOKEN = "";

    ENV_EUREKA_HOST_NAME = 'http://admin.staging.kpininja.com';
    ENV_EUREKA_IP_ADDRESS = 'http://admin.staging.kpininja.com';
    ENV_EUREKA_STATUS_PAGE_URL = 'http://admin.staging.kpininja.com';
    ENV_EUREKA_CLIENT_PORT = 80;
    ENV_EUREKA_HOST = 'universe.api.staging.kpininja.com';
    ENV_EUREKA_SERVER_PORT = 80;
    ENV_EUREKA_SERVICE_PATH = '/eureka/apps/';
} else if (SERVER_ENV.includes('idahohde.kpininja.com')) {
    ENV_APP_BASE = 'https://universe.idahohde.kpininja.com';
    ENV_API_BASE = 'https://universegateway.api.idahohde.kpininja.com/api/authmodule';
    ENV_API_BASE_URL = ENV_API_BASE + '';
    ENV_USER_BASE = 'https://universegateway.api.idahohde.kpininja.com/api/usermodule';
    ENV_USER_BASE_URL = 'https://users.idahohde.kpininja.com';
    ENV_ADMIN_BASE = 'https://universegateway.api.idahohde.kpininja.com/api/adminmodule';
    ENV_REPORT_BASE = 'https://universegateway.api.idahohde.kpininja.com/api/reportmodule';
    ENV_STRAGILE_BASE = 'https://universegateway.api.idahohde.kpininja.com/api/stragilemodule';
    ENV_REPORT_UI = 'https://report.idahohde.kpininja.com';
    ENV_BI_UI = 'https://bi.idahohde.kpininja.com';
    ENV_BASE_COOKIE = '.idahohde.kpininja.com';
    ENV_ROLLBAR_ACCESS_TOKEN = "";

    ENV_EUREKA_HOST_NAME = 'http://admin.idahohde.kpininja.com';
    ENV_EUREKA_IP_ADDRESS = 'http://admin.idahohde.kpininja.com';
    ENV_EUREKA_STATUS_PAGE_URL = 'http://admin.idahohde.kpininja.com';
    ENV_EUREKA_CLIENT_PORT = 80;
    ENV_EUREKA_HOST = 'universe.api.idahohde.kpininja.com';
    ENV_EUREKA_SERVER_PORT = 80;
    ENV_EUREKA_SERVICE_PATH = '/eureka/apps/';
} else if (SERVER_ENV.includes('preproduction.kpininja.com')) {
    ENV_APP_BASE = 'https://universe.preproduction.kpininja.com';
    ENV_API_BASE = 'https://universegateway.api.preproduction.kpininja.com/api/authmodule';
    ENV_API_BASE_URL = ENV_API_BASE + '';
    ENV_USER_BASE = 'https://universegateway.api.preproduction.kpininja.com/api/usermodule';
    ENV_USER_BASE_URL = 'https://user.preproduction.kpininja.com';
    ENV_ADMIN_BASE = 'https://universegateway.api.preproduction.kpininja.com/api/adminmodule';
    ENV_REPORT_BASE = 'https://universegateway.api.preproduction.kpininja.com/api/reportmodule';
    ENV_STRAGILE_BASE = 'https://universegateway.api.preproduction.kpininja.com/api/stragilemodule';
    ENV_REPORT_UI = 'https://report.preproduction.kpininja.com';
    ENV_BI_UI = 'https://bi.preproduction.kpininja.com';
    ENV_BASE_COOKIE = '.preproduction.kpininja.com';
    ENV_ROLLBAR_ACCESS_TOKEN = "";

    ENV_EUREKA_HOST_NAME = 'http://admin.preproduction.kpininja.com';
    ENV_EUREKA_IP_ADDRESS = 'http://admin.preproduction.kpininja.com';
    ENV_EUREKA_STATUS_PAGE_URL = 'http://admin.preproduction.kpininja.com';
    ENV_EUREKA_CLIENT_PORT = 80;
    ENV_EUREKA_HOST = 'universe.api.preproduction.kpininja.com';
    ENV_EUREKA_SERVER_PORT = 80;
    ENV_EUREKA_SERVICE_PATH = '/eureka/apps/';
} else if (SERVER_ENV.includes('.kpininja.com')) {
    ENV_APP_BASE = 'https://universe.kpininja.com';
    ENV_API_BASE = 'https://universe.api.kpininja.com/api/authmodule';
    ENV_API_BASE_URL = ENV_API_BASE + '';
    ENV_USER_BASE = 'https://universegateway.api.kpininja.com/api/usermodule';
    ENV_USER_BASE_URL = 'https://users.kpininja.com';
    ENV_ADMIN_BASE = 'https://universegateway.api.kpininja.com/api/adminmodule';
    ENV_REPORT_BASE = 'https://universegateway.api.kpininja.com/api/reportmodule';
    ENV_STRAGILE_BASE = 'https://universegateway.api.kpininja.com/api/stragilemodule';
    ENV_REPORT_UI = 'https://report.kpininja.com';
    ENV_BI_UI = 'https://bi.kpininja.com';
    ENV_BASE_COOKIE = '.kpininja.com';
    ENV_ROLLBAR_ACCESS_TOKEN = "";

    ENV_EUREKA_HOST_NAME = 'http://admin.kpininja.com';
    ENV_EUREKA_IP_ADDRESS = 'http://admin.kpininja.com';
    ENV_EUREKA_STATUS_PAGE_URL = 'http://admin.kpininja.com';
    ENV_EUREKA_CLIENT_PORT = 80;
    ENV_EUREKA_HOST = 'universe.api.kpininja.com';
    ENV_EUREKA_SERVER_PORT = 80;
    ENV_EUREKA_SERVICE_PATH = '/eureka/apps/';
} else if (SERVER_ENV.includes('preproduction.nehii.org')) {
    ENV_APP_BASE = 'https://ninjauniverse.preproduction.nehii.org';
    ENV_API_BASE = 'https://universegateway.api.preproduction.nehii.org/api/authmodule';
    ENV_API_BASE_URL = ENV_API_BASE + '';
    ENV_USER_BASE = 'https://universegateway.api.preproduction.nehii.org/api/usermodule';
    ENV_USER_BASE_URL = 'https://user.preproduction.nehii.org';
    ENV_ADMIN_BASE = 'https://universegateway.api.preproduction.nehii.org/api/adminmodule';
    ENV_BASE_COOKIE = '.preproduction.nehii.org';
    ENV_REPORT_BASE = 'https://universegateway.api.preproduction.nehii.org/api/reportmodule';
    ENV_STRAGILE_BASE = 'https://universegateway.api.preproduction.nehii.org/api/stragilemodule';
    ENV_REPORT_UI = 'https://report.preproduction.nehii.org';
    ENV_BI_UI = 'https://bi.preproduction.nehii.org';
    ENV_ROLLBAR_ACCESS_TOKEN = "ee5e835b19a745c582b6024bad40c2be";

    ENV_EUREKA_HOST_NAME = 'http://admin.preproduction.nehii.org';
    ENV_EUREKA_IP_ADDRESS = 'http://admin.preproduction.nehii.org';
    ENV_EUREKA_STATUS_PAGE_URL = 'http://admin.preproduction.nehii.org';
    ENV_EUREKA_CLIENT_PORT = 80;
    ENV_EUREKA_HOST = 'ninjauniverse.api.preproduction.nehii.org';
    ENV_EUREKA_SERVER_PORT = 80;
    ENV_EUREKA_SERVICE_PATH = '/eureka/apps/';
} else if (SERVER_ENV.includes('nehii.org')) {
    ENV_APP_BASE = 'https://ninjauniverse.nehii.org';
    ENV_API_BASE = 'https://universegateway.api.nehii.org/api/authmodule';
    ENV_API_BASE_URL = ENV_API_BASE + '';
    ENV_USER_BASE_URL = 'https://user.nehii.org';
    ENV_USER_BASE = 'https://universegateway.api.nehii.org/api/usermodule';
    ENV_ADMIN_BASE = 'https://universegateway.api.nehii.org/api/adminmodule';
    ENV_REPORT_BASE = 'https://universegateway.api.nehii.org/api/reportmodule';
    ENV_STRAGILE_BASE = 'https://universegateway.api.nehii.org/api/stragilemodule';
    ENV_REPORT_UI = 'https://report.nehii.org';
    ENV_BI_UI = 'https://bi.nehii.org';
    ENV_BASE_COOKIE = '.nehii.org';
    ENV_ROLLBAR_ACCESS_TOKEN = "ee5e835b19a745c582b6024bad40c2be";

    ENV_EUREKA_HOST_NAME = 'http://admin.nehii.org';
    ENV_EUREKA_IP_ADDRESS = 'http://admin.nehii.org';
    ENV_EUREKA_STATUS_PAGE_URL = 'http://admin.nehii.org';
    ENV_EUREKA_CLIENT_PORT = 80;
    ENV_EUREKA_HOST = 'ninjauniverse.api.nehii.org';
    ENV_EUREKA_SERVER_PORT = 80;
    ENV_EUREKA_SERVICE_PATH = '/eureka/apps/';
}

export const EUREKA_APP_NAME = 'u-admin-ui';
export const EUREKA_HOST_NAME = ENV_EUREKA_HOST_NAME;
export const EUREKA_IP_ADDRESS = ENV_EUREKA_IP_ADDRESS;
export const EUREKA_STATUS_PAGE_URL = ENV_EUREKA_STATUS_PAGE_URL;
export const EUREKA_CLIENT_PORT = ENV_EUREKA_CLIENT_PORT;
export const EUREKA_HOST = ENV_EUREKA_HOST;
export const EUREKA_SERVER_PORT = ENV_EUREKA_SERVER_PORT;
export const EUREKA_SERVICE_PATH = ENV_EUREKA_SERVICE_PATH;

export const AUTH_BASE_URL = ENV_APP_BASE;
export const API_BASE_URL = ENV_API_BASE_URL;
export const USER_BASE_API = ENV_USER_BASE + '/users';

export const USER_BASE_URL = ENV_USER_BASE_URL;
export const USER_BASE_UI = ENV_USER_BASE_URL + "/#/user";
export const ADMIN_BASE_API = ENV_ADMIN_BASE + '/admin';
export const ADMIN_REPORT_BASE_API = ENV_ADMIN_BASE + '/report';
export const STRAGILE_BASE_URL = ENV_STRAGILE_BASE + '/stragile';
export const BASE_COOKIE = ENV_BASE_COOKIE;
export const ROLLBAR_ACCESS_TOKEN = ENV_ROLLBAR_ACCESS_TOKEN;
export const REPORT_UI = ENV_REPORT_UI + '/#/report';
export const bI_UI = ENV_BI_UI + '/#/bi/';


// Validations
export const EMAIL_VALIDATION = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

export const ENV = {
    code_version: '1.1.1',
    environment: 'admin',
    RootModule: 1
};

export const ACCESS_TOKEN = 'accessToken';
export const OAUTH_TOKEN = '/oauth/token';

export const NOTIFICATION_BY_ID = '/fetchNotificationDetailById';
export const DOWNLOAD_NOTIFICATION_ATTACHMENT = '/downLoadReactAttachment';
export const FETCHMODULESBYQUERYPARAM_API = '/fetchModulesByQueryParam';

export const NOTIFICATION_LIST_AFTERLOGIN = '/showNotification';
export const NOTIFICATION_BY_ID_AFTERLOGIN = '/fetchNotificationDetailsById';
export const NOTIFICATION_LIST_AFTERLOGIN_EVENT = '/showNotificationEvent';
export const NOTIFICATION_READ = '/saveNotificationAsRead';
export const GETTIMEZONES_API = '/getTimezones';

export const CHECK_2_FACT = '/checkTwoFact';
export const CHECK_2_FACT_OTP = '/checkTwoFactOTP';
export const SEND_OTP = '/sendOTP';
export const RESEND_OPT = '/reSendOTP';

export const COMPANY_SIGNUP = '/companyRegistration';
export const CHECK_EMAIL = '/checkEmail';

export const FORGOT_PASSWORD = '/forgotPassword';
export const UPDATE_PASSWORD = '/updatePassword';

export const DASHBOARD_API = '/dashboard';
// export const USER_PROFILE_API = '/userprofile';
export const USER_PROFILE_API = '/userdetail';
export const WHITELABELING = '/whiteLabeling';

// NINJA ADVISOR
export const NINJA_ADVISOR = '/ninjaAdvisorInsert';

//USER PROJECT
export const GETUSERROLES = '/getUserModules';
export const USERDETAIL = '/userdetail';
export const CHECKBANNERSTATUS = '/checkBannerStatus';
export const GETPILLARSBYFY = '/getPillarsByFY';
export const GETHEADERSBYFY = '/getHeadersByFY';

//Report Project
export const EDIT_REPORT_URL = REPORT_UI + '/report?id=';
export const VIEW_REPORT_URL = REPORT_UI + '/viewreport?id=';

//Module Backend Name
export const ADMIN_MODULE_BACKEND_NAME = 'ADMIN';

//Something went wrong
export const SOMETHING_WENT_WRONG = "Something went wrong";