import {
    SET_PROFILE, SET_ROLES, SET_USERDETAIL, SET_WHITELABELING
} from "./action";

const initialState = {
    items: [],
    loading: false,
    error: null,
    profile: null,
    userdetailObject: {},
    whiteLabeling: {}
};

export default function urlReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case SET_ROLES:
            return {
                ...state,
                items: action.payload.roles
            };
        case SET_WHITELABELING:
            return {
                ...state,
                whiteLabeling: action.payload.response
            };
        case SET_USERDETAIL:
            return {
                ...state,
                userdetailObject: action.payload.object
            };
        case SET_PROFILE:
            return {
                ...state,
                profile: action.payload.profile
            };
        default:
            // ALWAYS have a default case in a reducer
            return state;
    }
}

