import {
    API_BASE_URL,
    ACCESS_TOKEN,
    CHECK_2_FACT,
    OAUTH_TOKEN,
    SEND_OTP,
    CHECK_2_FACT_OTP,
    RESEND_OPT,
    CHECK_EMAIL,
    COMPANY_SIGNUP,
    USER_BASE_API,
    DASHBOARD_API,
    USER_PROFILE_API,
    WHITELABELING,
    FORGOT_PASSWORD,
    UPDATE_PASSWORD,
    NOTIFICATION_LIST_AFTERLOGIN,
    NOTIFICATION_BY_ID_AFTERLOGIN,
    NOTIFICATION_READ,
    DOWNLOAD_NOTIFICATION_ATTACHMENT,
    NOTIFICATION_LIST_AFTERLOGIN_EVENT,
    NINJA_ADVISOR,
    GETTIMEZONES_API,
    NOTIFICATION_BY_ID, FETCHMODULESBYQUERYPARAM_API
} from '../Constants';
import Cookies from 'universal-cookie';
import {request} from '../Services/UtilService';
const USERKEY = 'C_KPININJA';
const SECRETKEY = 'S_KPININJA';

//Utility functions

// NOTIFICATION API

export async function notification() {
    const req = await fetch(API_BASE_URL+NOTIFICATION_LIST_AFTERLOGIN+'?count=0',{
        method: 'GET',
        headers: {
            Authorization: "Basic "+ btoa(USERKEY+":"+SECRETKEY),
            'content-type': 'application/json'
        },

        json: true
    });
    const res = await req.json();

    return res;
}


// USERS API

export async function whitelabelingAPI() {
    return await request({
        url: USER_BASE_API + WHITELABELING,
        method: 'GET'
    })
}

//NINJA ADVISOR


export  default async function makeAndHandleRequestOld(query, page = 1) {
    const SEARCH_URI = USER_BASE_API + FETCHMODULESBYQUERYPARAM_API;
    return await request({
        url: `${SEARCH_URI}?query=${query}&page=${page}&perPage=50`,
        method: 'GET'
    })

}

