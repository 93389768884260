import {Redirect, Route, Switch} from "react-router-dom";
import React, {Fragment, lazy, Suspense} from "react";
import Loader from "react-loaders";
import {LockScreen}from "u-header";
import {ToastContainer} from "react-toastify";
import Cookies from "universal-cookie";
import {APP_BASE, AUTH_BASE_URL, BASE_COOKIE, USER_BASE_API} from '../../Constants'


//const Profile = lazy(() => import("../../DemoPages/Dashboards/AllUserLogs"));


//Admin
//All Admin
const Admin = lazy(() => import("../../Admin"));
const NotFound = lazy(() => import("../../404"));


 const LoadingMessage = () => (
    <div className="loader-container">
        <div className="loader-container-inner">
            <div className="text-center">
                <Loader type='ball-pulse'/>
            </div>
        </div>
    </div>
);
let defaultRedirect = "/";
let authenticated;
let lockScreen;

const AppMain = ({userObj}) => {
    const cookies = new Cookies();

    if (!cookies.get('lock')) {
        cookies.set('lock', false,{ httpOnly: false,domain : BASE_COOKIE});
    } else {
        lockScreen = cookies.get('lock');
    }

    if (cookies.get('access_token')) {

        if (cookies.get('lock').toString() === 'true') {
            lockScreen = true;
        } else {
            lockScreen = false;
        }
        defaultRedirect = '/admin/allAdmin';
        authenticated = true;

    } else {
        window.location.href = AUTH_BASE_URL;
        authenticated = false;
    }

    const lock = () => {

        let url = window.location.href;
        cookies.set('lock', true,{ httpOnly: false,domain : BASE_COOKIE});
        window.location.href = AUTH_BASE_URL + '#/pages/lock-screen?url=' + url;
    };

    const logout = () => {
            let url = window.location.href;
            cookies.remove('access_token', {httpOnly: false, domain: BASE_COOKIE});
            cookies.remove('refresh_token', {httpOnly: false, domain: BASE_COOKIE});
            cookies.remove('lock', {httpOnly: false, domain: BASE_COOKIE});
            window.location.href = AUTH_BASE_URL + "/#/pages/login" ;
    };

    return (
        <Fragment>
          <LockScreen userObj={userObj} baseCookie={BASE_COOKIE} authUrl={AUTH_BASE_URL} userBaseAPI={USER_BASE_API}/>
            {/* Components */}
            <Suspense fallback={<LoadingMessage />}>
                <Switch>
                    <Route
                        path="/admin"
                        component={Admin}
                    />

                    <Route path="/404">
                        <NotFound />
                    </Route>
                    <Route path="**" render={() => (
                        <Redirect to="/404"/>
                    )}/>
                    {/*<Route path="**">*/}
                    {/*<NotFound />*/}
                    {/*</Route>*/}
                </Switch>
            </Suspense>


            <Route exact path="/" render={() => <Redirect to={defaultRedirect} />} />
            <ToastContainer />
        </Fragment>
    );
};

export default AppMain;
