import {
    GETUSERROLES,
    USER_BASE_API,
    USERDETAIL,
    CHECKBANNERSTATUS,
    GETPILLARSBYFY,BASE_COOKIE,
    GETHEADERSBYFY, AUTH_BASE_URL,
} from "../Constants";
import {
    whitelabelingAPI
} from '../Utils/APIUtils';
import Cookies from "universal-cookie";
import axios from 'axios';
import {handle403Page} from 'u-header';

export const request = options => {
    const cookies = new Cookies();
    cookies.set("isBannerStarted", false, {httpOnly: false, domain: BASE_COOKIE});
    if (cookies.get("access_token", {httpOnly: false})) {
        let AUTH_TOKEN = "Bearer " + cookies.get("access_token", {httpOnly: false});
        axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
    }
    const config = {
        headers: {'Content-Type': 'application/json'},
        url: options['url'],
        method: options['method'],
        data: options['body']
    };

    if(navigator.onLine) {
        return axios.request(config)
            .then(response => {
                if (response.config.headers && response.headers.authorization) {
                    cookies.set("access_token", response.headers && response.headers.authorization, {
                        httpOnly: false,
                        domain: BASE_COOKIE
                    });
                }
                return response.data;
            })
            .catch((error) => {
                let response;
                if (error && error.response) {
                    if (error.response.status === 401) {
                        cookies.remove('access_token', {httpOnly: false, domain: BASE_COOKIE});
                        cookies.remove('refresh_token', {httpOnly: false, domain: BASE_COOKIE});
                        cookies.remove('lock', {httpOnly: false, domain: BASE_COOKIE});
                        window.location.href = AUTH_BASE_URL + '/#/pages/login'
                    } else if (error.response.status === 403) {
                        handle403Page(AUTH_BASE_URL);
                    } else if (error && error.response && parseInt(error.response.status, 10) === 500) {
                        response = {
                            status: 502,
                            tempMassage: "Bad Gateway"
                        };
                        return response;
                    } else {
                        response = {
                            status: false,
                            tempMassage: "Bad Gateway"
                        };
                        return response;
                    }
                } else {
                    response = {
                        status: false,
                        tempMassage: "Bad Gateway"
                    };
                    return response;
                }
            })
    } else {
        return {statue: false, tempMassage: "INTERNET DISCONNECTED"}
    }
};

export const request2 = (options) => {
    const config = {
        headers: {},
        url: options['url'],
        method: options['method'],
        data: options['body']
    };
    const cookies = new Cookies();
    cookies.set("isBannerStarted", false, {httpOnly: false, domain: BASE_COOKIE});
    if (cookies.get('access_token', {httpOnly: false})) {
        config['headers']['Authorization'] = '' + cookies.get('access_token', {httpOnly: false});
    }
    if(navigator.onLine) {
        return axios.request(config).then(response => {
            if (response.config.headers && response.headers.authorization) {
                cookies.set("access_token", response.headers && response.headers.authorization, {
                    httpOnly: false,
                    domain: BASE_COOKIE
                });
            }
            return response.data;
        })
            .catch((error) => {
                let response;
                if (error && error.response) {
                    if (error.response.status === 401) {
                        cookies.remove('access_token', {httpOnly: false, domain: BASE_COOKIE});
                        cookies.remove('refresh_token', {httpOnly: false, domain: BASE_COOKIE});
                        cookies.remove('lock', {httpOnly: false, domain: BASE_COOKIE});
                        window.location.href = AUTH_BASE_URL + '/#/pages/login'
                    } else if (error.response.status === 403) {
                        handle403Page(AUTH_BASE_URL);
                    }  else if (error && error.response && parseInt(error.response.status, 10) === 500) {
                        response = {
                            status: 502,
                            tempMassage: "Bad Gateway"
                        };
                        return response;
                    } else {
                        response = {
                            status: false,
                            tempMassage: "Something went wrong"
                        };
                        return response;
                    }
                } else {
                    response = {
                        status: false,
                        tempMassage: "Something went wrong"
                    };
                    return response;
                }
            })
    } else {
        return {statue: false, tempMassage: "INTERNET DISCONNECTED"}
    }
};

export async function userDetailsAPI() {
    return await request({
        url: USER_BASE_API + USERDETAIL,
        method: 'get',
    });
}




export function getDateString(d) {
    let date = new Date(d);
    return  + appendLeadingZeroes(date.getMonth() + 1) + '/' + appendLeadingZeroes(date.getDate())+'/'+date.getFullYear();
}
function appendLeadingZeroes(n) {
    if (n <= 9) {
        return '0' + n;
    }
    return n;
}
